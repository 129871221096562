<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(addTmp)" @reset.prevent="reset">
                <b-overlay :show="loading">
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organization"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formAssign.org_id"
                          :options="organizationList"
                          :disabled="isDisable"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formAssign.service_id"
                            :options="serviceList"
                            :disabled="isDisable"
                            id="service_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Step Name" vid="step_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="step_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('step_name.step_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="details.step_id"
                            :options="stepList"
                            id="step_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Form" vid="form_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="form_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.form') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="details.application_id"
                            :options="formList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Form Type" vid="form_type" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="form_type"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('irriPumpInformation.form_type') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="details.form_type"
                            :options="formTypeList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.add') }}</b-button>
                    </div>
                  </div>
                </b-overlay>
              </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" enctype="multipart/form-data">
                <br>
                <b-row>
                  <b-table-simple  bordered>
                    <thead>
                      <tr class="bg-primary">
                        <th scope="col">{{ $t('globalTrans.sl_no') }}</th>
                        <th scope="col">{{ $t('step_name.step_name') }}</th>
                        <th scope="col">{{ $t('dynamic_form.form_title') }}</th>
                        <th scope="col">{{ $t('irriPumpInformation.form_type') }}</th>
                        <th scope="col" class="text-center">{{ $t('globalTrans.action') }}</th>
                      </tr>
                    </thead>
                    <b-tbody>
                      <b-tr v-for="(item, index) in formAssign.details" :key="index">
                        <b-td> {{ $n(index + 1) }} </b-td>
                        <b-td> {{ getStepName(item.step_id) }} </b-td>
                        <b-td> {{ getFormName(item.application_id) }} </b-td>
                        <b-td> {{ getFormType(item.form_type) }} </b-td>
                        <b-td>
                          <b-button type="button" @click="remove(index)" class="btn btn- iq-bg-danger btn-sm text-center ml-1"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { formAssignStore, formAssignUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['item', 'id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      details: {
        step_id: 0,
        application_id: 0,
        form_type: 0
      },
      formAssign: {
        org_id: 0,
        service_id: 0,
        details: []
      },
      update: false,
      formList: [],
      serviceList: [],
      stepList: [],
      isDisable: false,
      formTypeList: [
        { value: 1, text: this.$t('globalTrans.action') },
        { value: 2, text: this.$t('globalTrans.entry') }
      ]
    }
  },
  created () {
    this.formAssign.org_id = this.userCheck()
    if (this.id) {
      this.isDisable = true
      this.update = true
      const tmp = this.getServiceStepData(this.item)
      this.formAssign.org_id = this.item.org_id
      this.formAssign.service_id = this.item.service_id
      this.formAssign.details = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formAssign.org_id': function (newVal) {
      this.stepList = []
      this.serviceList = this.getServiceList(newVal)
    },
    'formAssign.service_id': function (newVal) {
      this.stepList = this.getStepList(newVal)
    },
    'details.step_id': function (n, o) {
       if (n !== o) {
        this.formList = this.getStepWiseForm(n)
       }
    }
  },
  methods: {
    getServiceList (orgId) {
      // return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
      const serviceList = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
      const formasigned = this.$store.state.licenseRegistration.commonObj.serviceStepForm
      if (this.id) {
        return serviceList
      } else {
        const newServiceList = []
        serviceList.forEach(item => {
          const findForm = formasigned.find(assignedItem => assignedItem.service_id === item.value && assignedItem.org_id === item.org_id)
          if (!findForm) {
            if (this.$i18n.locale === 'bn') {
              newServiceList.push({ value: item.value, text: item.text_en })
            } else {
              newServiceList.push({ value: item.value, text: item.text_en })
            }
          }
        })
        return newServiceList
      }
    },
    addTmp () {
      // const applicationId = this.details.application_id
      // const stepId = this.details.step_id
      if (this.formAssign.org_id !== 0 && this.formAssign.service_id !== 0) {
        this.isDisable = true
      }
      // const checkService = this.formAssign.details.find(item => item.application_id === applicationId && item.step_id === stepId)
      // const checkEntryForm = this.formAssign.details.find(item => item.form_type === 2 && this.details.form_type === 2)
      // if (typeof checkService === 'undefined' && typeof checkEntryForm === 'undefined') {
        this.formAssign.details.push(JSON.parse(JSON.stringify(this.details)))
        this.details.step_id = 0
        this.details.application_id = 0
        this.details.form_type = 0
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      // }
    },
    getServiceStepData (data) {
      const detailData = []
      Object.entries(data.details).forEach(([key, value]) => {
          const stepItem = { step_id: value.step_id, application_id: value.form_id, form_type: value.form_type }
          detailData.push(stepItem)
      })
      return detailData
    },
    async storeUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${formAssignUpdate}/${this.id}`, this.formAssign)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, formAssignStore, this.formAssign)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
      this.loading = false
    },
    remove (key) {
      this.formAssign.details.splice(key, 1)
    },
    getStepName (stepId) {
      const tmpStep = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(step => step.value === stepId)
      return this.$i18n.locale === 'en' ? tmpStep !== undefined ? tmpStep.text_en : '' : tmpStep !== undefined ? tmpStep.text_bn : ''
    },
    getFormName (applicationId) {
      const tmpStep = this.$store.state.licenseRegistration.commonObj.dynamicFormList.find(item => item.value === applicationId)
      return this.$i18n.locale === 'en' ? tmpStep !== undefined ? tmpStep.text_en : '' : tmpStep !== undefined ? tmpStep.text_en : ''
    },
    getFormType (typeID) {
      const tmpStep = this.formTypeList.find(item => item.value === typeID)
      return tmpStep !== undefined ? tmpStep.text : ''
    },
    getStepWiseForm () {
      const tmpFormList = this.$store.state.licenseRegistration.commonObj.dynamicFormList.filter(item => item.status === 1)
      const formasigned = this.$store.state.licenseRegistration.commonObj.serviceStepForm
      const newList = []
      tmpFormList.forEach(item => {
        const findForm = formasigned.find(assignedItem => assignedItem.form_id === item.value && assignedItem.service_id !== this.formAssign.service_id)
        if (!findForm) {
          if (this.$i18n.locale === 'bn') {
            newList.push({ value: item.value, text: item.text_en })
          } else {
            newList.push({ value: item.value, text: item.text_en })
          }
        }
      })
      return newList
    },
    getStepList (serviceId) {
      const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === serviceId)
      const stepList = tmpStepList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.step_id, text: item.step_name_bn }
        } else {
          return { value: item.step_id, text: item.step_name }
        }
      })
      return stepList
    }
  }
}
</script>
